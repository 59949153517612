import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Box, Typography } from '@mui/material';

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <Container>
            {/* Favicon Section */}
            <Box display="flex" justifyContent="center" mt={4}>
            <a href="https://madeby.zaka.ai/" target="_blank" rel="noopener noreferrer">
                <img
                    src={`${process.env.PUBLIC_URL}/favicon.png`}
                    alt="Logo"
                    style={{
                        height: 120, // Adjust height for sizing
                        width: 120, // Adjust width for sizing
                    }}
                />
             </a>

            </Box>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="calc(100vh - 350px)" // Adjusting height for layout
                style={{ textAlign: 'center', paddingTop: '20px' }}
            >
                {/* Title Section */}
                <Typography
                    variant="h2"
                    style={{
                        fontFamily: 'Space Mono',
                        marginBottom: '20px',
                        color: 'white',
                        marginTop: '-30px', // Adjust height for positioning
                    }}
                >
                    Welcome to Career Wizard
                </Typography>

                {/* Description Below Title */}
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Space Mono',
                        color: 'white',
                        marginBottom: '40px',
                        maxWidth: '820px', // Limit width for readability
                    }}
                >
                    Unleash your career potential with our CV and LinkedIn Analyzers! Discover personalized,
                    in-depth insights to refine your resume and LinkedIn profile, ensuring you stand out to employers with confidence!
                </Typography>

                {/* Buttons Next to Each Other */}
                <Box display="flex" gap="16px">
                    <Button
                        variant="contained"
                        onClick={() => navigate('/cv-wizard')}
                        style={{
                            fontFamily: 'Space Mono',
                            fontSize: '16px',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            backgroundColor: 'rgb(162, 194, 78)', // Green color
                            color: 'black',
                            minWidth: '250px', // Ensure both buttons have the same width
                        }}
                    >
                        Go to CV Wizard
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/linkedin-wizard')}
                        style={{
                            fontFamily: 'Space Mono',
                            fontSize: '16px',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            backgroundColor: 'rgb(162, 194, 78)', // Green color
                            color: 'black',
                            minWidth: '250px', // Ensure both buttons have the same width
                        }}
                    >
                        Go to LinkedIn Wizard
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default HomePage;
