import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarAlert = ({ error, showError, handleCloseError }) => (
    <Snackbar
        open={showError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
        <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{
                backgroundColor: 'red',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Space Mono',
                '& .MuiAlert-icon': {
                    color: 'white',
                },
            }}
        >
            {error}
        </Alert>
    </Snackbar>
);

export default SnackbarAlert;
