import React from 'react';
import { Box, Typography } from '@mui/material';

const Header = ({ title }) => (
    <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={4}>
        <Typography
            variant="h3"
            style={{
                color: 'white',
                fontFamily: 'Space Mono',
            }}
        >
            {title}
        </Typography>
        <a href="https://madeby.zaka.ai/" target="_blank" rel="noopener noreferrer">
            <img
                src={`${process.env.PUBLIC_URL}/favicon.png`}
                alt="Logo"
                style={{ height: 80, marginLeft: 20, cursor: 'pointer' }} // Add cursor pointer for visual feedback
            />
        </a>
    </Box>
);

export default Header;
