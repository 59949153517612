import React from 'react';
import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ onDrop, processing }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc', '.docx'],
        },
        maxFiles: 1,
    });

    return (
        <Box
            {...getRootProps()}
            style={{
                flex: 1,
                padding: 16,
                backgroundColor: 'rgb(162, 194, 78)',
                textAlign: 'center',
                marginRight: 16,
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
                cursor: 'pointer',
            }}
        >
            <input {...getInputProps()} />
            <Typography
                style={{
                    color: 'black',
                    fontFamily: 'Space Mono',
                }}
            >
                {processing ? 'Processing...' : 'Drag \'n\' drop a file here, or click to select (PDF/Word, max 2MB)'}
            </Typography>
        </Box>
    );
};

export default FileUpload;
