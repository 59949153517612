import React from 'react';
import { Typography } from '@mui/material';

const TabContent = ({ tabIndex, comments }) => {
    const renderContent = (content) =>
        content.map((line, index) => {
            const isTitle = line.startsWith('- T:') || line.startsWith('- P:') || line.startsWith('- N:');
            if (isTitle) {
                const color = line.startsWith('- P:')
                    ? 'rgb(162, 194, 78)'
                    : line.startsWith('- N:')
                        ? 'red'
                        : 'rgb(162, 194, 78)';
                const prefix = line.startsWith('- P:') ? '- P:' : line.startsWith('- N:') ? '- N:' : 'T:';
                const titleStart = line.indexOf(prefix) + prefix.length;
                const titleEnd = line.indexOf(':', titleStart);
                const title = line.substring(titleStart, titleEnd).trim();
                const content = line.substring(titleEnd + 1).trim();

                return (
                    <div key={index} style={{ marginBottom: '1em' }}>
                        <span style={{ color, fontWeight: 'bold' }}>{title}:</span>
                        <br />
                        <span>{content}</span>
                    </div>
                );
            }
            return <p key={index}>{line}</p>;
        });

    switch (tabIndex) {
        case 0: // General Comments
            return <Typography style={{ color: 'white', fontFamily: 'Space Mono' }}>{renderContent(comments.details)}</Typography>;
        case 1: // Work Experience
            return <Typography style={{ color: 'white', fontFamily: 'Space Mono' }}>{renderContent(comments.work)}</Typography>;
        case 2: // Education
            return <Typography style={{ color: 'white', fontFamily: 'Space Mono' }}>{renderContent(comments.education)}</Typography>;
        default:
            return null;
    }
};

export default TabContent;
